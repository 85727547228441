import React from "react"
import "../styles/Feedback.css"
// import stars from "../images/stars.png"
import sever from "../images/sever.png"
import andrei from "../images/andrei.png"
import wulf from "../images/wulf.png"
import Whirligig from "react-whirligig"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/free-solid-svg-icons"
import { registerURL } from "./Const"

export default function Feedback() {
  let whirligig
  const next = () => whirligig.next()
  const prev = () => whirligig.prev()

  return (
    <div>
      {/* 
    <div className="feedback-container">
      <section className="feedback-top">
        <div className="pn-text reaim-padding">
          <span className="left-showcase-logo">
            <span className="left-showcase-text">TESTIMONIALS</span>
          </span>
          <h1 className="feedback-header-h1">What our users say</h1>
        </div>

        <div className="feedback-switch" id="feedback-switch">
          <div className="left-arrow-side" onClick={prev}>
            <span className="left-arrow-fix"></span>
          </div>

          <div className="right-arrow-side" onClick={next}>
            <span className="right-arrow-fix"></span>
          </div>
        </div>
      </section>

      <section className="feedback-cards">
        <Whirligig
          preventScroll={true}
          visibleSlides={0}
          animationDuration="500"
          gutter="3em"
          ref={_whirligigInstance => {
            whirligig = _whirligigInstance
          }}
        >
          <div className="feedback-card-item">
            <div className="font-awesome-cont">
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faStar}
                color={"rgb(248, 172, 73)"}
              />
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faStar}
                color={"rgb(248, 172, 73)"}
              />
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faStar}
                color={"rgb(248, 172, 73)"}
              />
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faStar}
                color={"rgb(248, 172, 73)"}
              />
              <FontAwesomeIcon icon={faStar} color={"rgb(248, 172, 73)"} />
            </div>
            <p className="feedback-card-p">
              Tech virtual drone online browser platform through in a system.
              But stream software offline. Professor install angel sector
              anywhere create at components smart. Document fab developers
              encryption smartphone powered, bespoke blockstack edit atoms
            </p>
            <div className="feedback-p-hr"></div>
            <div className="feedback-user">
              <img className="feedback-user-img" src={sever} alt="sever" />
              <span className="feedback-user-span">
                <p className="feedback-user-span-p1">Sever Tasic</p>
                <br />
                <p className="feedback-user-span-p2">Entrepreneur, Reaim</p>
              </span>
            </div>
          </div>

          <div className="feedback-card-item">
            <div className="font-awesome-cont">
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faStar}
                color={"rgb(248, 172, 73)"}
              />
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faStar}
                color={"rgb(248, 172, 73)"}
              />
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faStar}
                color={"rgb(248, 172, 73)"}
              />
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faStar}
                color={"rgb(248, 172, 73)"}
              />
              <FontAwesomeIcon icon={faStar} color={"rgb(248, 172, 73)"} />
            </div>
            <p className="feedback-card-p">
              Cryptocurrency bespoke decentralized. In a smart home. Companies
              privacy build at activists data. privacy Ultra-private funding
              apps. strategy startups onocutive computer.
            </p>
            <div className="feedback-p-hr"></div>
            <div className="feedback-user">
              <img className="feedback-user-img" src={andrei} alt="sever" />
              <span className="feedback-user-span">
                <p className="feedback-user-span-p1">Andrei Masharin</p>
                <br />
                <p className="feedback-user-span-p2">Entrepreneur, Reaim</p>
              </span>
            </div>
          </div>

          <div className="feedback-card-item">
            <div className="font-awesome-cont">
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faStar}
                color={"rgb(248, 172, 73)"}
              />
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faStar}
                color={"rgb(248, 172, 73)"}
              />
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faStar}
                color={"rgb(248, 172, 73)"}
              />
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faStar}
                color={"rgb(248, 172, 73)"}
              />
              <FontAwesomeIcon icon={faStar} color={"rgb(248, 172, 73)"} />
            </div>
            <p className="feedback-card-p">
              Now digital designs id anywhere atoms. Now strategy startups
              documents designs. Venture crypto adopters niche. Video algorithm
              system ultra-private policies engineering. Users takedowns. In
              apps torrent, decentralized bespoke IPO funding, change word
              company. e- commerce components goods support in file system edit
              steem on videos engineering agorithm hundreds.
            </p>
            <div className="feedback-p-hr"></div>
            <div className="feedback-user">
              <img className="feedback-user-img" src={wulf} alt="sever" />
              <span className="feedback-user-span">
                <p className="feedback-user-span-p1">Wulf Kindler</p>
                <br />
                <p className="feedback-user-span-p2">Entrepreneur, Reaim</p>
              </span>
            </div>
          </div>

          <div className="feedback-card-item">
            <div className="font-awesome-cont">
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faStar}
                color={"rgb(248, 172, 73)"}
              />
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faStar}
                color={"rgb(248, 172, 73)"}
              />
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faStar}
                color={"rgb(248, 172, 73)"}
              />
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faStar}
                color={"rgb(248, 172, 73)"}
              />
              <FontAwesomeIcon icon={faStar} color={"rgb(248, 172, 73)"} />
            </div>
            <p className="feedback-card-p">
              Cryptocurrency bespoke decentralized. In a smart home. Companies
              privacy build at activists data. privacy Ultra-private funding
              apps. strategy startups onocutive computer.
            </p>
            <div className="feedback-p-hr"></div>
            <div className="feedback-user">
              <img className="feedback-user-img" src={andrei} alt="sever" />
              <span className="feedback-user-span">
                <p className="feedback-user-span-p1">Andrei Masharin</p>
                <br />
                <p className="feedback-user-span-p2">Entrepreneur, Reaim</p>
              </span>
            </div>
          </div>

          <div className="feedback-card-item">
            <div className="font-awesome-cont">
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faStar}
                color={"rgb(248, 172, 73)"}
              />
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faStar}
                color={"rgb(248, 172, 73)"}
              />
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faStar}
                color={"rgb(248, 172, 73)"}
              />
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faStar}
                color={"rgb(248, 172, 73)"}
              />
              <FontAwesomeIcon icon={faStar} color={"rgb(248, 172, 73)"} />
            </div>
            <p className="feedback-card-p">
              Tech virtual drone online browser platform through in a system.
              But stream software offline. Professor install angel sector
              anywhere create at components smart. Document fab developers
              encryption smartphone powered, bespoke blockstack edit atoms
            </p>
            <div className="feedback-p-hr"></div>
            <div className="feedback-user">
              <img className="feedback-user-img" src={sever} alt="sever" />
              <span className="feedback-user-span">
                <p className="feedback-user-span-p1">Sever Tasic</p>
                <br />
                <p className="feedback-user-span-p2">Entrepreneur, Reaim</p>
              </span>
            </div>
          </div>

          <div className="feedback-card-item">
            <div className="font-awesome-cont">
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faStar}
                color={"rgb(248, 172, 73)"}
              />
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faStar}
                color={"rgb(248, 172, 73)"}
              />
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faStar}
                color={"rgb(248, 172, 73)"}
              />
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faStar}
                color={"rgb(248, 172, 73)"}
              />
              <FontAwesomeIcon icon={faStar} color={"rgb(248, 172, 73)"} />
            </div>
            <p className="feedback-card-p">
              Now digital designs id anywhere atoms. Now strategy startups
              documents designs. Venture crypto adopters niche. Video algorithm
              system ultra-private policies engineering. Users takedowns. In
              apps torrent, decentralized bespoke IPO funding, change word
              company. e- commerce components goods support in file system edit
              steem on videos engineering agorithm hundreds.
            </p>
            <div className="feedback-p-hr"></div>
            <div className="feedback-user">
              <img className="feedback-user-img" src={wulf} alt="sever" />
              <span className="feedback-user-span">
                <p className="feedback-user-span-p1">Wulf Kindler</p>
                <br />
                <p className="feedback-user-span-p2">Entrepreneur, Reaim</p>
              </span>
            </div>
          </div>

          <div className="feedback-card-item">
            <div className="font-awesome-cont">
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faStar}
                color={"rgb(248, 172, 73)"}
              />
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faStar}
                color={"rgb(248, 172, 73)"}
              />
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faStar}
                color={"rgb(248, 172, 73)"}
              />
              <FontAwesomeIcon
                className="font-awesome-icon"
                icon={faStar}
                color={"rgb(248, 172, 73)"}
              />
              <FontAwesomeIcon icon={faStar} color={"rgb(248, 172, 73)"} />
            </div>
            <p className="feedback-card-p">
              Cryptocurrency bespoke decentralized. In a smart home. Companies
              privacy build at activists data. privacy Ultra-private funding
              apps. strategy startups onocutive computer.
            </p>
            <div className="feedback-p-hr"></div>
            <div className="feedback-user">
              <img className="feedback-user-img" src={andrei} alt="sever" />
              <span className="feedback-user-span">
                <p className="feedback-user-span-p1">Andrei Masharin</p>
                <br />
                <p className="feedback-user-span-p2">Entrepreneur, Reaim</p>
              </span>
            </div>
          </div>
        </Whirligig>
      </section>
      */}
      <div className="satisfied-user-btn-holder">
        <a
          className="showcase-btn rwd-features-btn-size feedback-btn-large"
          href={registerURL}
          alt="Try it for Free"
        >
          <span className="showcase-btn-text">
            Become Our Satisfied Customer
          </span>
          <span className="right-arrow rwd-features-right-arrow"></span>
        </a>
        <h6 className="btn-credit-card btn-credit-card-work feedback-btn-credit-card">
          * No Credit Card Required
        </h6>
      </div>
      <div className="feedback-under-btn-holder">
        <h1 className="feedback-under-btn-text">
          If you invest money in marketing without re-marketing - you throw your
          money.
        </h1>
      </div>
      <div className="feedback-under-btn-holder feedback-product-hunt">
        <a
          href="https://www.producthunt.com/posts/reaim?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-reaim"
          target="_blank"
        >
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=227933&theme=light&period=daily"
            alt="ReAim - Platform to make sending push-notifications easier | Product Hunt Embed"
            width="250px"
            height="54px"
          />
        </a>
      </div>
      {/* 
    </div>
    */}
    </div>
  )
}
