import React from "react"
import "../styles/Work.css"
import one from "../images/one.png"
import two from "../images/two.png"
import three from "../images/three.png"
import four from "../images/four.png"
import workpic1 from "../images/workpic1.svg"
import workpic2 from "../images/workpic2.svg"
import workpic3 from "../images/workpic3.svg"
import workpic4 from "../images/workpic4.svg"
import shopifyLogo from "../images/shopifyLogo.png"
import html5Logo from "../images/html5Logo.png"
import wordpressLogo from "../images/wordpressLogo.png"
import squarespaceLogo from "../images/squarespaceLogo.png"
// import drupalLogo from "../images/drupalLogo.png"
import workpic1rwd from "../images/workpic1rwd.svg"
import workpic2rwd from "../images/workpic2rwd.svg"
import workpic3rwd from "../images/workpic3rwd.svg"
import workpic4rwd from "../images/workpic4rwd.svg"
import { registerURL } from "./Const"

export default function Work() {
  return (
    <div className="work-container" id="how-does-it-work">
      {/* Its simple span and how does it work text */}
      <section className="pn-text work-header">
        <span className="left-showcase-logo">
          <span className="left-showcase-text rwd-work-logo">IT'S SIMPLE</span>
        </span>
        <h1 className="work-header-h1">How does it work?</h1>
      </section>
      {/* Work tabs flex */}
      <section className="work-tabs">
        {/* First work tab */}
        <div className="work-tab1 first-work-tab">
          <div className="rwd-work-tab first-work-tab2">
            <span className="circle-list-blog-span1 circle-position cp-one">
              <span className="circle-list-blog-span2 fix-block"></span>
            </span>
            <div className="work-tab-number1">
              <h1 className="header-rel rwd-work-header">
                <img src={one} alt="number one" className="icon-fix1" />
                Register
                <br /> on Platform
              </h1>
            </div>
          </div>
          <div className="work-tab-img1">
            <img src={workpic1} alt="first img" className="img-fix2" />
            <img src={workpic1rwd} alt="first img" className="img-fix2-rwd" />
          </div>
        </div>

        {/* Second work tab */}
        <div className="work-tab1 work-tab-fix">
          <div className="rwd-work-tab">
            <span className="circle-list-blog-span1 circle-position cp-two">
              <span className="circle-list-blog-span2 fix-block"></span>
            </span>

            <div className="work-tab-number1">
              <h1 className="header-rel work-header-fix">
                <img src={two} alt="number two" className="icon-fix2" />
                Integrate
                <br /> with us
              </h1>
            </div>
          </div>
          <div className="work-tab-img1">
            <img src={workpic2} alt="second img" className="img-fix2" />
            <img src={workpic2rwd} alt="second img" className="img-fix2-rwd" />
          </div>
        </div>

        {/* Third work tab */}
        <div className="work-tab1 work-tab3">
          <div className="rwd-work-tab">
            <span className="circle-list-blog-span1 circle-position cp-three">
              <span className="circle-list-blog-span2 fix-block"></span>
            </span>

            <div className="work-tab-number1">
              <h1 className="header-rel rwd-work-header">
                <img src={three} alt="number three" className="icon-fix3" />
                Collect
                <br /> Subscribers
              </h1>
            </div>
          </div>
          <div className="work-tab-img1">
            <img src={workpic3} alt="third img" className="img-fix2" />
            <img src={workpic3rwd} alt="third img" className="img-fix2-rwd" />
          </div>
        </div>
        {/* Fourth work tab */}
        <div className="work-tab1 work-tab4">
          <div className="rwd-work-tab">
            <span className="circle-list-blog-span1 circle-position cp-last">
              <span className="circle-list-blog-span2 fix-block"></span>
            </span>

            <div className="work-tab-number1">
              <h1 className="header-rel rwd-work-header">
                <img src={four} alt="number four" className="icon-fix4" />
                Send notifications
                <br /> to your customers
              </h1>
            </div>
          </div>
          <div className="work-tab-img1">
            <img src={workpic4} alt="forth img" className="img-fix2" />
            <img src={workpic4rwd} alt="forth img" className="img-fix2-rwd" />
          </div>
        </div>
      </section>

      {/*Try it for free button and text */}
      <section className="work-btn rwd-work-btn-fix">
        <a className="showcase-btn" href={registerURL} alt="Try it for Free">
          <span className="showcase-btn-text">Sign Up</span>
          <span className="right-arrow"></span>
        </a>
        <h6 className="btn-credit-card btn-credit-card-work rwd-btn-credit-card-work">
          * No Credit Card Required
        </h6>
        <p className="work-btn-text">
          We have done calculation. It takes 3 minutes to integrate.
        </p>
        <h3 className="work-btn-text-support">We Support These Platforms:</h3>
      </section>
      {/* Logos section */}
      <section className="logos">
        <div className="logos-flex">
          <img
            className="logos-flex-display shopify-logo"
            src={shopifyLogo}
            alt="Shopify Logo"
          />
          <img
            className="logos-flex-display wordpress-logo"
            src={wordpressLogo}
            alt="Wordpress Logo"
          />
          <img
            className="logos-flex-display html5-logo"
            src={html5Logo}
            alt="HTML5 Logo"
          />
          <img
            className="logos-flex-display squarespace-logo"
            src={squarespaceLogo}
            alt="SquareSpace Logo"
          />
        </div>
      </section>
    </div>
  )
}
