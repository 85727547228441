import React from "react"
import "../styles/Circle.css"
import { registerURL } from "./Const"

export default function Circle() {
  return (
    <div className="circle-container">
      {/* Text on the top of the circle */}
      <div className="header-flex" id="is-it-for-me">
        <section className="pn-text">
          <span className="left-showcase-logo">
            <span className="left-showcase-text">USES</span>
          </span>
          <h1 className="circle-header-h1">
            Is it for <span className="left-showcase-letters">me?</span>
          </h1>
          <p className="p-z-index circle-header-p">
            If you're an owner of a website, the answer is YES!
          </p>
        </section>
      </div>
      <a
        className="showcase-btn rwd-circle-showcase-btn"
        href={registerURL}
        alt="Try it for Free"
      >
        <span className="showcase-btn-text">Sign Up</span>
        <span className="right-arrow"></span>
      </a>
      {/* Circle flex container */}
      <div className="circle-flex">
        <div className="circle-list2">
          <ul>
            <span className="circle-list-blog-span1 rwd-circle-bg fix-z-index-block fix-circle-left1">
              <span className="circle-list-blog-span2 fix-block"></span>
            </span>

            <li className="blog circle-list-item">Blog</li>

            <span className="circle-list-blog-span1 rwd-circle-bg fix-z-index-block fix-circle-left2">
              <span className="circle-list-blog-span2 fix-block"></span>
            </span>

            <li className="brochure circle-list-item">SaaS</li>

            <span className="circle-list-blog-span1 rwd-circle-bg fix-z-index-block fix-circle-left3">
              <span className="circle-list-blog-span2 fix-block"></span>
            </span>

            <li className="crowfunding circle-list-item">Crowdfunding</li>

            <span className="circle-list-blog-span1 rwd-circle-bg fix-z-index-block fix-circle-left4">
              <span className="circle-list-blog-span2 fix-block"></span>
            </span>

            <li className="ecommerce circle-list-item">Ecommerce</li>

            <span className="circle-list-blog-span1 rwd-circle-bg fix-z-index-block fix-circle-left5">
              <span className="circle-list-blog-span2 fix-block"></span>
            </span>

            <li className="media circle-list-item">Media</li>

            <span className="circle-list-blog-span1 rwd-circle-bg fix-z-index-block fix-circle-left6">
              <span className="circle-list-blog-span2 fix-block"></span>
            </span>

            <li className="non-profit circle-list-item">Non-Profit</li>
          </ul>
        </div>
        <div className="circle">
          <div className="inner-circle">
            <div className="inner-circle2">
              <a
                className="inner-circle-href"
                href={registerURL}
                alt="Try for free"
              >
                <span className="inner-circle-href-text">Sign Up</span>
              </a>
            </div>
          </div>
        </div>
        <div className="circle-list1">
          <ul>
            <span className="circle-list-blog-span1 rwd-circle-bg fix-circle-right1 fix-block">
              <span className="circle-list-blog-span2 fix-block"></span>
            </span>

            <li className="business circle-list-item">Forum</li>

            <span className="circle-list-blog-span1 rwd-circle-bg fix-circle-right2 fix-block">
              <span className="circle-list-blog-span2 fix-block"></span>
            </span>

            <li className="crowfunding2 circle-list-item">
              Affiliate Marketing
            </li>

            <span className="circle-list-blog-span1 rwd-circle-bg fix-circle-right3 fix-block">
              <span className="circle-list-blog-span2 fix-block"></span>
            </span>

            <li className="personal-portfolio circle-list-item">
              Personal & Portfolio
            </li>

            <span className="circle-list-blog-span1 rwd-circle-bg fix-circle-right4 fix-block">
              <span className="circle-list-blog-span2 fix-block"></span>
            </span>

            <li className="educational circle-list-item">Educational</li>

            <span className="circle-list-blog-span1 rwd-circle-bg fix-circle-right5 fix-block">
              <span className="circle-list-blog-span2 fix-block"></span>
            </span>

            <li className="entertainment circle-list-item">Entertainment</li>

            <span className="circle-list-blog-span1 rwd-circle-bg fix-circle-right6  fix-block">
              <span className="circle-list-blog-span2 fix-block"></span>
            </span>

            <li className="portal circle-list-item">Portal</li>
          </ul>
        </div>
      </div>
    </div>
  )
}
