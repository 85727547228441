import React from "react"
import "../styles/Normalize.css"
import "../styles/App.css"
import "../styles/index.css"
import "../fonts/Manrope-Regular.otf"
import "../fonts/Manrope-Bold.otf"
import "../fonts/Manrope-Medium.otf"
import "../fonts/Manrope-SemiBold.otf"
import "../fonts/SpaceGrotesk-Bold.otf"
import "../fonts/SpaceGrotesk-Regular.otf"
import "../fonts/SFProText-Semibold.ttf"
import Navigation from "../components/Navigation"
import Header from "../components/Header"
import PushNotification from "../components/PushNotification"
import Circle from "../components/Circle"
import Work from "../components/Work"
import Features from "../components/Features"
import FeaturesLine from "../components/FeaturesLine"
import FeaturesBottom from "../components/FeaturesBottom"
import Feedback from "../components/Feedback"
import Footer from "../components/Footer"
import { Helmet } from "react-helmet"

const IndexPage = () => (
  <div className="parent">
    <Helmet>
      <title>ReAim - Reach your audience with push notifications</title>
    </Helmet>
    <Navigation />
    <Header />
    <PushNotification />
    <Circle />
    <Work />
    <Features />
    <FeaturesLine />
    <FeaturesBottom />
    <Feedback />
    <Footer />
  </div>
)

export default IndexPage
