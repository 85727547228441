import React from "react"
import "../styles/Header.css"
import reactangle from "../images/reactangle.png"
import icon1 from "../images/icon1.svg"
import icon2 from "../images/icon2.svg"
import icon3 from "../images/icon3.svg"
import icon4 from "../images/icon4.svg"
import circlepng from "../images/circlepng.png"
import video2 from "../images/video2.mp4"
import { registerURL } from "./Const"

export default function Header() {
  return (
    <div className="main-container">
      <div className="container">
        <div className="fix-showcase-padding">
          {/* Showcase section - under navigation */}
          <section className="showcase">
            {/* Left showcase div with text and buttons */}
            <div className="left-showcase">
              <span className="left-showcase-logo rwd-logo">
                <span className="left-showcase-text">Push Notifications</span>
              </span>
              <h1>
                Reach{" "}
                <span className="left-showcase-letters">your audience</span>{" "}
                <br />
                even if they aren't <br /> on{" "}
                <span className="left-showcase-letters">your website</span>
              </h1>
              <p>
                Send an offer, news, notice or any type of information you'd
                like <br /> to your users, no matter where they are or what they
                are doing.
              </p>
              {/* Try it for free button */}
              <a
                className="showcase-btn rwd-showcase-btn"
                href={registerURL}
                alt="Try it for Free"
              >
                <span className="showcase-btn-text">Sign Up</span>
                <span className="right-arrow"></span>
              </a>
              <h6 className="btn-credit-card rwd-btn-credit-card-header">
                * No Credit Card Required
              </h6>
            </div>
            <div className="circle-png">
              <img src={circlepng} className="circle-png-img" alt="circle" />
            </div>
            {/* Right showcase div with image */}
            <div className="right-showcase">
              <video
                className="right-showcase-video"
                preload="auto"
                muted
                autoPlay
                loop
                poster={reactangle}
              >
                <source src={video2} type="video/mp4"></source>
                Your browser does not support the video tag.
              </video>
            </div>
          </section>
          {/* Bottom showcase */}
          <section className="bottom-showcase">
            <div className="bottom-showcase-header">
              <span className="left-showcase-logo">
                <span className="left-showcase-text">HOW?</span>
              </span>
              <h1>Push Notifications Are Here</h1>
            </div>
            {/* Bottom showcase div with 4 icons */}
            <div className="bottom-showcase-tags">
              <div>
                <div className="rwd-bottom-showcase">
                  <div className="icon-bg">
                    <img
                      src={icon1}
                      className="icon-position"
                      alt="icon1"
                    ></img>
                  </div>
                  <h3>Don't miss out on Visitors</h3>
                </div>
                <p>
                  90% of your visitors will never <br /> come back. With
                  ReAim.me <br /> convert your visitors into your <br />{" "}
                  <span className="bottom-showcase-tags-span">
                    subscribers.
                  </span>
                </p>
              </div>
              <div>
                <div className="rwd-bottom-showcase">
                  <div className="icon-bg">
                    <img
                      src={icon2}
                      className="icon-position"
                      alt="icon2"
                    ></img>
                  </div>
                  <h3>No App Needed</h3>
                </div>
                <p>
                  You don't need an app. <br />
                  This is a web push notification.
                </p>
              </div>
              <div>
                <div className="rwd-bottom-showcase">
                  <div className="icon-bg">
                    <img
                      src={icon3}
                      className="icon-position"
                      alt="icon3"
                    ></img>
                  </div>
                  <h3>Premium Remarketing</h3>
                </div>
                <p>
                  You will reach your users
                  <br />
                  anywhere and anytime with a<br />
                  message directly shown on their
                  <br /> screen.
                </p>
              </div>
              <div>
                <div className="rwd-bottom-showcase">
                  <div className="icon-bg">
                    <img
                      src={icon4}
                      className="icon-position"
                      alt="icon4"
                    ></img>
                  </div>
                  <h3>Click Through Rate</h3>
                </div>
                <p>
                  <span className="span-12">12%</span> Push Notification
                  <br />
                  <span className="span-space"></span>{" "}
                  <span className="span-3">3%</span> Email
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}
