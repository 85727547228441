import React from "react"
import circlepng from "../images/circlepng.png"
import { registerURL } from "./Const"
import "../styles/Features.css"

export default function TestinCo() {
  return (
    <section className="cta-title">
      <div className="circle-features">
        <img src={circlepng} alt="circle" />
      </div>
      <div className="cta-title-text">
        <h1>Don’t miss the opportunity!</h1>
        <p className="feature-p-padding">
          You now have 2 options. You can leave the site and miss perhaps the
          best opportunity to improve your business or completely free of charge
          without entering a credit card try a modern way of remarketing that
          has 4x better open rate than mail marketing. And which will allow you
          to reach your subscribers whether they are in the office, at a
          concert, dinner or chatting with the best friend.
        </p>
      </div>
      <section className="work-btn features-btn-fix">
        <a
          className="showcase-btn rwd-features-btn-size"
          href={registerURL}
          alt="Try it for Free"
        >
          <span className="showcase-btn-text">Sign Up</span>
          <span className="right-arrow"></span>
        </a>
        <h6 className="btn-credit-card btn-credit-card-work rwd-btn-credit-card-features">
          * No Credit Card Required
        </h6>
      </section>
    </section>
  )
}
