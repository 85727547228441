import React from "react"
import "../styles/Features.css"
import stats from "../images/stats.svg"
import segment from "../images/segment.svg"
import cart from "../images/cart.svg"
import modal from "../images/modal.svg"
import targeting from "../images/targeting.svg"
import notify from "../images/notify.svg"
import sched from "../images/sched.svg"
import coll from "../images/coll.svg"
import wel from "../images/wel.svg"

export default function Features({ customStyle }) {
  return (
    <div style={customStyle} className="features-container" id="features">
      {/* Why reaim span and Features h1 */}
      <section className="pn-text reaim-padding">
        <span className="left-showcase-logo">
          <span className="left-showcase-text">WHY REAIM</span>
        </span>
        <h1 className="features-header-h1">Features</h1>
      </section>
      {/* Grid container */}
      <section className="grid-section">
        {/* 1/3 grid container */}
        <div className="first-grid">
          <div className="feature-item feature-item1">
            <div className="rwd-features">
              <div>
                <img src={stats} alt="stats" />
              </div>
              <h1 className="h-padding">Live Statistics</h1>
            </div>
            <p className="features-p-font">
              Monitors the behavior of its users in real time. This will help
              you optimize your campaigns.
            </p>
          </div>

          <div className="feature-item">
            <div className="rwd-features">
              <div>
                <img src={cart} alt="cart" />
              </div>
              <h1 className="h-padding">Abandoned Cart</h1>
            </div>
            <p className="features-p-font">
              If you're an e-commerce, retreive your customers to finish their
              purchase.
            </p>
          </div>

          <div className="feature-item feature-item3">
            <div className="rwd-features">
              <div>
                <img src={modal} alt="modal" />
              </div>
              <h1 className="h-padding">Custom Modal Window</h1>
            </div>
            <p className="features-p-font">
              Customize prompt that asks your users to subscribe according to
              your brand.
            </p>
          </div>
        </div>
        {/* 2/3 grid container */}
        <div className="second-grid">
          <div className="feature-item">
            <div className="rwd-features">
              <div>
                <img src={segment} alt="segment" />
              </div>
              <h1 className="h-padding">Segmentation</h1>
            </div>
            <p className="features-p-font">
              You will be able to create narrowly targeted audience segments, to
              more accurately target your audience.
            </p>
          </div>

          <div className="feature-item target-col-fix">
            <div className="rwd-features">
              <div>
                <img src={targeting} alt="targeting" />
              </div>
              <h1 className="h-padding h-targeting-padding">Targeting</h1>
            </div>
            <p className="features-p-font">
              You will be able to target your audience with multiple attributes.
              By country, browsers, OS, device types, subscription age, revenue
              generated, etc.
            </p>
          </div>

          <div className="feature-item">
            <div className="rwd-features">
              <div>
                <img src={notify} alt="notification" />
              </div>
              <h1 className="h-padding">Welcome Notification</h1>
            </div>
            <p className="features-p-font">
              Send welcome notifications upon subscriptions, to automatically
              engage your subscribers.
            </p>
          </div>
        </div>
        {/* 3/3 grid container */}
        <div className="third-grid">
          <div className="feature-item">
            <div className="rwd-features">
              <div>
                <img src={sched} alt="scheduled" />
              </div>
              <h1 className="h-padding">Scheduled</h1>
            </div>
            <p className="features-p-font">
              Determine when your campaign will run. Target users in their
              timezone.
            </p>
          </div>

          <div className="feature-item target-col-fix">
            <div className="rwd-features">
              <div>
                <img src={coll} alt="collect" />
              </div>
              <h1 className="h-padding">Collect by clicks</h1>
            </div>
            <p className="features-p-font">
              We will collect users who have clicked on your notifications, and
              you will be able to run remarketing campaigns.
            </p>
          </div>

          <div className="feature-item">
            <div className="rwd-features">
              <div>
                <img src={wel} alt="welcome" />
              </div>
              <h1 className="h-padding">A/B/N Testing</h1>
            </div>
            <p className="features-p-font">
              A/B testing is one of the crucial parts in marketing, so we have
              made a system that allows you to test up to 10 creatives at once.
            </p>
          </div>
        </div>
      </section>
      {/* CTA title and try it for free button */}
    </div>
  )
}
