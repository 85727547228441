import React, { useState, useEffect, useRef } from "react"
import "../styles/PushNotification.css"
import andriodIcon from "../images/androidIcon.svg"
import macIcon from "../images/macIcon.svg"
import windowsIcon from "../images/windowsIcon.svg"
import image12 from "../images/image12.svg"
//import uploading from "../images/uploading.svg"
import plus from "../images/plus.svg"
import InputPN from "../components/InputPN"

export default function LeftPushNotification({
  first,
  second,
  third,
  onSystemChange,
  currentSystem,
  iconSetter,
}) {
  const [firstText, setFirstText] = useState(
    "Push Notifications are revolution"
  )
  const [secondText, setSecondText] = useState(
    "Businesses which dont't know for remarketing today, don't have a chance to survive, try for a free best remarketing revolution."
  )
  const [URLText, setURLText] = useState("")

  const [stateIcon1, setStateIcon1] = useState(false)
  const [stateIcon2, setStateIcon2] = useState(false)

  const pnIcon1 = useRef("")
  const pnIcon2 = useRef("")
  const pnIcon3 = useRef("")
  const imgOutput1 = useRef("")
  const imgOutput2 = useRef("")

  useEffect(() => {
    first(firstText)
    second(secondText)
    third(URLText)
  }, [firstText, secondText, URLText, first, second, third])

  const handleFirstText = data => {
    setFirstText(data)
    if (data.length < 2) {
      setFirstText("Push Notifications are revolution")
    }
  }

  const handleSecondText = data => {
    setSecondText(data)
    if (data.length < 2) {
      setSecondText(
        "Businesses which dont't know for remarketing today, don't have a chance to survive, try for a free best remarketing revolution."
      )
    }
  }

  const handleURLText = data => {
    setURLText(data)
  }

  useEffect(() => {
    currentSystem === "android"
      ? pnIcon1.current.classList.add("pn-icon-active")
      : pnIcon1.current.classList.remove("pn-icon-active")
    currentSystem === "mac"
      ? pnIcon2.current.classList.add("pn-icon-active")
      : pnIcon2.current.classList.remove("pn-icon-active")
    currentSystem === "windows"
      ? pnIcon3.current.classList.add("pn-icon-active")
      : pnIcon3.current.classList.remove("pn-icon-active")
  }, [currentSystem])

  const loadFile1 = e => {
    imgOutput1.current.src = URL.createObjectURL(e.target.files[0])
    imgOutput1.current.classList.remove("iconAfterX1")
    imgOutput1.current.classList.add("image-output1")
    iconSetter(imgOutput1.current.src)
    setStateIcon1(true)
    imgOutput1.current.src = image12
  }

  const loadFile2 = e => {
    imgOutput2.current.src = URL.createObjectURL(e.target.files[0])
    imgOutput2.current.classList.remove("iconAfterX2")
    imgOutput2.current.classList.add("image-output2")
    setStateIcon2(true)
    imgOutput2.current.src = image12
  }

  const effectIcon1 = () => {
    imgOutput1.current.classList.remove("image-output1")
    imgOutput1.current.classList.add("iconAfterX1")
    imgOutput1.current.src = plus
    iconSetter("")
    setStateIcon1(false)
  }

  const effectIcon2 = () => {
    imgOutput2.current.classList.remove("image-output2")
    imgOutput2.current.classList.add("iconAfterX2")
    imgOutput2.current.src = plus
    setStateIcon2(false)
  }

  return (
    <div>
      <div className="pn-left-tab">
        {/*Web-Android, Mac and Windows Icons */}
        <div className="pn-left-tab-icons">
          <div
            ref={pnIcon1}
            className="pn-icons"
            value="android"
            onClick={() => {
              onSystemChange("android")
            }}
          >
            <img src={andriodIcon} alt="android icon" />
            {currentSystem === "android" && (
              <span className="pn-icon-text">Web - Android</span>
            )}
          </div>
          <div
            ref={pnIcon2}
            className="pn-icons"
            value="mac"
            onClick={() => {
              onSystemChange("mac")
            }}
          >
            <img src={macIcon} alt="mac icon" />
            {currentSystem === "mac" && (
              <span className="pn-icon-text">Web - MacOS</span>
            )}
          </div>
          <div
            ref={pnIcon3}
            className="pn-icons"
            value="windows"
            onClick={() => {
              onSystemChange("windows")
            }}
          >
            <img src={windowsIcon} alt="windows icon" />
            {currentSystem === "windows" && (
              <span className="pn-icon-text">Web - Windows</span>
            )}
          </div>
        </div>
        {/*h5 and inputs */}

        <h5 className="pn-campaign-h5 pn-h5-font">Title</h5>

        <InputPN
          placeholder="Notification Title"
          value={firstText}
          handler={data => handleFirstText(data)}
          styles={{
            fontFamily: "Manrope",
            fontWeight: "700",
            fontSize: "20px",
            lineHeight: "26px",
            color: " rgb(2, 14, 23, 50%)",
          }}
        />
        <h5 className="pn-padding pn-h5-font">Description</h5>

        <InputPN
          placeholder="Add Description"
          value={secondText}
          handler={data => handleSecondText(data)}
          styles={{
            fontFamily: "Manrope",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "24px",
            color: "rgb(2, 14, 23, 80%)",
          }}
        />
        <h5 className="pn-padding pn-h5-font">URL</h5>

        <InputPN
          value={URLText}
          handler={data => handleURLText(data)}
          placeholder="Enter URL"
          styles={{
            fontFamily: "Manrope",
            fontWeight: "700",
            fontSize: "20px",
            lineHeight: "26px",
            color: " rgb(2, 14, 23, 50%)",
          }}
        />
        {/*Icon, image and badge */}
        <div className="pn-left-tab-badges">
          <div className="icon-badge">
            <h5 className="pn-h5-font pnIconh5-margin">Icon</h5>
            <label>
              <img
                src={plus}
                ref={imgOutput1}
                className="icon-image12"
                alt="image12"
              />
              <input
                onChange={loadFile1}
                type="file"
                accept="image/*"
                style={{
                  display: "none",
                }}
              />
            </label>
          </div>
          <div className="image-badge">
            <h5 className="icon-uploading-h5 pn-h5-font pnImageh5-margin">
              Badge
            </h5>
            <label>
              <img
                src={plus}
                ref={imgOutput2}
                className="icon-uploading"
                alt="uploading"
              />
              <input
                onChange={loadFile2}
                type="file"
                accept="image/*"
                style={{
                  display: "none",
                }}
              />
            </label>
          </div>
          {stateIcon1 && (
            <div className="pn-x pn-x1" id="iconX1" onClick={effectIcon1}>
              x
            </div>
          )}

          {stateIcon2 && (
            <div className="pn-x pn-x2" id="iconX2" onClick={effectIcon2}>
              x
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
