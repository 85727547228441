import React, { useState } from "react"
import "../styles/PushNotification.css"
import LeftPushNotification from "../components/LeftPushNotification"
import RightPushNotification from "../components/RightPushNotification"
import circlepng from "../images/circlepng.png"

export default function PushNotification() {
  const [firstText, setFirstText] = useState("")
  const [secondText, setSecondText] = useState("")
  const [URLText, setURLText] = useState("")
  const [systemImageData, setSystemImageData] = useState("android")
  const [getIcon, setIcon] = useState("")

  const handleFirstText = data => {
    setFirstText(data)
  }

  const handleSecondText = data => {
    setSecondText(data)
  }

  const handleThirdText = data => {
    setURLText(data)
  }

  return (
    <div className="pn-container" id="push-notification">
      <div className="circle-pn">
        <img src={circlepng} alt="circle" />
      </div>
      {/* Try it in real time span and pn text */}
      <div className="pn-text-cont">
        <section className="pn-text">
          <span className="left-showcase-logo">
            <span className="left-showcase-text">TRY IT IN REAL TIME</span>
          </span>
          <h1 className="pn-text-h1">
            What is a <br />
            <span className="left-showcase-letters">Push Notification?</span>
          </h1>
        </section>
        <section className="pn-text-right-side"></section>
      </div>
      {/* Left push notification and right push notification */}
      <section className="pn-tabs">
        <LeftPushNotification
          first={data => handleFirstText(data)}
          second={data => handleSecondText(data)}
          third={data => handleThirdText(data)}
          onSystemChange={setSystemImageData}
          currentSystem={systemImageData}
          iconSetter={setIcon}
        />
        <RightPushNotification
          dsp={firstText}
          dsp2={secondText}
          systemImage={systemImageData}
          dsp3={URLText}
          iconGetter={getIcon}
        />
      </section>
    </div>
  )
}
