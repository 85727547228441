import React, { useEffect, useRef } from "react"
import "../styles/PushNotification.css"
import android from "../images/android.png"
import mac from "../images/mac.png"
import windows from "../images/windows.png"
import chrome from "../images/chrome.png"

export default function RightPushNotification({
  dsp,
  dsp2,
  systemImage,
  dsp3,
  iconGetter,
}) {
  const setImage = () => {
    if (systemImage === "windows") {
      return windows
    } else if (systemImage === "mac") {
      return mac
    } else {
      return android
    }
  }

  const iconGetter1 = useRef("")

  useEffect(() => {
    let imageList = [android, mac, windows]
    imageList.forEach(image => {
      new Image().src = image
    })
  }, [])

  const openLink = () => {
    window.open(dsp3)
  }

  useEffect(() => {
    iconGetter === ""
      ? (iconGetter1.current.style.display = "none")
      : (iconGetter1.current.style.display = "block")
  }, [iconGetter])

  return (
    <div>
      <div className="pn-right-tab">
        <img
          src={setImage()}
          id="rightPN"
          className={`rightPN-image rightPN-image--${systemImage}`}
          alt="Phone"
        />
        <div
          onClick={openLink}
          className={`description-input system--${systemImage}`}
        >
          <div className="icon-container">
            <img src={chrome} alt="Chrome" className="chrome-png" />
            <div className={`chrome-text-container--${systemImage}`}>
              Chrome <span className="chrome-span-dot"></span> reaim.me
            </div>
            <div className={`chrome-hr--${systemImage}`} />
            <div
              className={`icon-getter icon-getter--${systemImage}`}
              ref={iconGetter1}
            >
              <img
                src={iconGetter}
                className={`icon-getter-img icon-getter-img--${systemImage}`}
              />
            </div>
          </div>
          <p className={`dsp-input dsp--${systemImage}`}>{dsp}</p>
          <p className={`chrome-reaim2--${systemImage}`}>reaim.me</p>
          <p className={`dsp-input2 dsp2--${systemImage}`}>{dsp2}</p>
          <p className={`chrome-reaim--${systemImage}`}>reaim.me</p>
        </div>
      </div>
    </div>
  )
}
